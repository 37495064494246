import { gql, useQuery, makeVar, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';

const widgetParamsVar = makeVar(null);

export const setWidgetParams = ({ campaignId = null, config = {}, widgetId = null }) => {
  if (!widgetId && !campaignId) {
    throw new Error('DonationFormWidget requires either a widgetId or a campaignId');
  }

  widgetParamsVar({ campaignId, config, widgetId });
};

export const useWidgetParams = (params) => {
  useEffect(() => {
    const currentValue = widgetParamsVar();
    if (params && !isEqual(params, currentValue)) {
      setWidgetParams({
        ...currentValue,
        ...params,
        config: { ...currentValue?.config, ...params.config },
      });
    }
  }, [params]);

  return useReactiveVar(widgetParamsVar);
};

export const CAMPAIGN_FRAGMENT = gql`
  fragment DonationFormWidgetCampaignFields on Campaign {
    id
    canDonorCoverPlatformFee
    canDonorCoverProcessorFee
    contactEmail
    donationPresets
    isRecurringGivingEnabled
    ownerOrganization {
      id
      logo
      name
      paymentProcessorConfig
    }
    recurringDonationPresets
    supportsHourlyRecurringDonations
    supportsDailyRecurringDonations
    supportsWeeklyRecurringDonations
    supportsMonthlyRecurringDonations
    supportsQuarterlyRecurringDonations
    supportsYearlyRecurringDonations

    collectDonorAddress
    collectDonorPhone

    donationQuestions(order: "sort", where: { isEnabled: true }) {
      id
      sort
      type
      label
      isEnabled
      isRequired
      helpText
      config
      options(order: "sort", where: { isEnabled: true }) {
        id
        sort
        label
        isEnabled
      }
    }
  }
`;

export const CAMPAIGN_QUERY = gql`
  ${CAMPAIGN_FRAGMENT}
  query DonationFormWidgetCampaign($campaignId: String!) {
    findCampaigns(id: $campaignId) {
      ...DonationFormWidgetCampaignFields
    }
  }
`;

export const WIDGET_QUERY = gql`
  ${CAMPAIGN_FRAGMENT}
  query DonationFormWidgetRecord($widgetId: String!) {
    findEmbedWidgets(id: $widgetId) {
      id
      campaign {
        ...DonationFormWidgetCampaignFields
      }
      config
      type
    }
  }
`;

export const useDonationFormWidget = (initialParams) => {
  const params = useWidgetParams(
    initialParams ? { ...initialParams, config: initialParams.config ?? {} } : undefined
  );

  const widgetQuery = useQuery(WIDGET_QUERY, {
    variables: { widgetId: params?.widgetId },
    skip: !params?.widgetId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    onCompleted: ({ findEmbedWidgets }) => {
      if (!findEmbedWidgets[0] || findEmbedWidgets[0].type !== 'donationForm') {
        throw new Error('Invalid widgetId');
      }
    },
  });

  const campaignQuery = useQuery(CAMPAIGN_QUERY, {
    variables: { campaignId: params?.campaignId },
    skip: !params?.campaignId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  const campaign = useMemo(() => {
    if (campaignQuery.data) return campaignQuery.data.findCampaigns[0];
    return widgetQuery.data?.findEmbedWidgets[0]?.campaign;
  }, [widgetQuery.data, campaignQuery.data]);

  const config = useMemo(
    () => ({ ...widgetQuery.data?.findEmbedWidgets[0]?.config, ...params?.config }),
    [widgetQuery.data, params]
  );

  return {
    loading: widgetQuery.loading || campaignQuery.loading,
    error: widgetQuery.error || campaignQuery.error,
    campaign,
    config,
  };
};
