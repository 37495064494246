import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentElement } from '@stripe/react-stripe-js';
import { motion } from 'framer-motion';
import capitalize from 'lodash/capitalize';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import globalConfig from 'config';
import cx from 'lib/cx';
import { formatCurrency } from 'lib/formatters';
import { makeRequired } from 'lib/validators';
import Button from 'components/common/Button';
import FormCheckbox from 'components/form/FormCheckbox';
import inputStyle from 'components/form/FormInput.module.css';
import FormNode from 'components/form/FormNode';
import FormValidationError from 'components/form/FormValidationError';
import DonationFormStep from './DonationFormStep';
import useDonationFormCreateDonation from './useDonationFormCreateDonation';
import { updateData, useData } from './useDonationFormData';
import useDonationFormAmounts from './useDonationFormAmounts';
import { getNextStep, setStep } from './useDonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';

const ANSWER_QUESTION = gql`
  mutation AnswerDonationQuestion(
    $questionId: String!
    $donationId: String!
    $answer: SequelizeJSON!
  ) {
    answerQuestion(questionId: $questionId, audienceId: $donationId, answer: $answer)
  }
`;

const validationSchema = yup.object({
  hasCard: makeRequired(yup.boolean()),
});

const DonationFormPayment = ({ isPreview }) => {
  const [showFeeDialog, setShowFeeDialog] = useState(false);

  const { campaign, config } = useDonationFormWidget();
  const { coverFee, ...data } = useData();
  const amounts = useDonationFormAmounts();
  const canCoverFee = campaign.canDonorCoverPlatformFee || campaign.canDonorCoverProcessorFee;
  const isRecurring = data.type === 'recurring_flat';

  const [answerQuestion] = useMutation(ANSWER_QUESTION);
  const createDonation = useDonationFormCreateDonation();

  const { handleSubmit, control, setError, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (canCoverFee && coverFee === undefined) {
      updateData({ coverFee: true });
    }
  }, [canCoverFee, coverFee]);

  const onSubmit = async () => {
    if (isPreview) return;

    try {
      const result = await createDonation();

      const responses = Object.entries(data).filter(
        ([k, v]) => k.startsWith('question:') && v !== null
      );
      await Promise.all(
        responses.map(([k, answer]) =>
          answerQuestion({
            variables: {
              questionId: k.replace('question:', ''),
              answer,
              donationId: result.donationId,
            },
          })
        )
      );

      setStep(getNextStep());
    } catch (err) {
      if (err.type === 'CardError') {
        setError('hasCard', {
          message: `There was issue processing your payment. ${err.message}`,
        });
      } else {
        if (globalConfig('/debug')) console.error(err);
        setError('hasCard', { message: 'There was an error processing your payment' });
      }
    }
  };

  return (
    <DonationFormStep
      action={
        <Button
          as="button"
          type="button"
          color="primary"
          padding="sm"
          className="text-lg font-medium w-full"
          style={{ color: config.formButtonLabelColor }}
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
          ) : (
            <span className="inline-flex items-center gap-3">
              <span className="font-normal">{formatCurrency(amounts.total)}</span>
              Submit Payment
            </span>
          )}
        </Button>
      }
    >
      <h1 className="text-xl font-medium mb-5">Billing</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" className="hidden" />

        <Controller
          control={control}
          name="hasCard"
          render={({ field, fieldState }) => (
            <FormNode>
              <div
                ref={field.ref}
                className={cx('overflow-hidden', inputStyle.default, {
                  [inputStyle.error]: fieldState.error,
                })}
              >
                <PaymentElement
                  onChange={({ complete }) => field.onChange(complete)}
                  options={{
                    fields: {
                      billingDetails: {
                        name: 'never',
                        email: 'never',
                        address: {
                          country: campaign.collectDonorAddress === 'required' ? 'never' : 'auto',
                          postalCode:
                            campaign.collectDonorAddress === 'required' ? 'never' : 'auto',
                        },
                      },
                    },
                  }}
                />
              </div>
              {fieldState.error && <FormValidationError message={fieldState.error.message} />}
            </FormNode>
          )}
        />

        <div
          className={cx('space-y-3 mt-10', {
            hidden: !canCoverFee,
          })}
        >
          <div className="flex items-center gap-x-4 font-medium text-lg leading-none">
            <p className="flex-1">Donation</p>
            <p className="shrink-0">{formatCurrency(amounts.donation, { cents: 'always' })}</p>
          </div>
          <div className="flex items-center gap-x-4 text-lg leading-none">
            <p className="flex-1">
              Cover fees{' '}
              <button
                type="button"
                className="ml-2 px-1 py-0.5 border border-theme-primary text-theme-primary rounded text-xs font-medium"
                onClick={() => setShowFeeDialog(!showFeeDialog)}
              >
                {showFeeDialog ? 'Done' : 'Change'}
              </button>
            </p>
            <p className={cx('shrink-0', { 'line-through': !coverFee })} data-testid="fees-value">
              {formatCurrency(amounts.fees)}
            </p>
          </div>
          {showFeeDialog && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'linear' }}
              className="border border-gray-300 rounded-md text-sm px-4 mt-3"
            >
              <FormCheckbox
                label={`I'd like to cover all transaction fees so that 100% of my donation goes to ${campaign.ownerOrganization.name}`}
                themed
                borderNone
                value={coverFee}
                onChange={(value) => updateData({ coverFee: value })}
              />
            </motion.div>
          )}
        </div>
        <div className="flex items-center gap-x-4 text-xl font-medium leading-none mt-8">
          <p className="flex-1">
            {isRecurring ? `${capitalize(data.recurringFrequency)} total` : 'Total'}
          </p>
          <p className="shrink-0" data-testid="fees-value">
            {formatCurrency(amounts.total)}
          </p>
        </div>
      </form>
    </DonationFormStep>
  );
};

DonationFormPayment.propTypes = {
  isPreview: PropTypes.bool,
};

DonationFormPayment.defaultProps = {
  isPreview: false,
};

export default DonationFormPayment;
